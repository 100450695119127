@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin-bottom: $spacing-small;
}

.button-group {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button {
  @include label-medium;

  display: flex;
  padding: 10px $spacing-large;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  color: $gray-80;
  border-color: transparent;
  background: $gray-10;

  &.active {
    background: $blue-10;
    color: $blue-60;
  }

  &:not(:nth-last-child(1)) {
    border-right: 1px solid $gray-20;
  }

  &:nth-child(1) {
    border-bottom-left-radius: $radius-big;
    border-top-left-radius: $radius-big;
  }

  &:nth-last-child(1) {
    border-bottom-right-radius: $radius-big;
    border-top-right-radius: $radius-big;
  }
}
