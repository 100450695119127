@import "src/global";

.anchor {
  white-space: nowrap;
}

.label-static-part {
  @include label-medium;
  color: $gray-90;
}

.label-variable-part {
  @include label-medium;
  color: $gray-80;
}

.sort-target {
  @include body-medium;
  color: $gray-90;
}

.sort-order {
  @include body-medium;
  color: $gray-70;
}

.icon {
  flex-shrink: 0;
}
