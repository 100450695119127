@import "src/global";

@include tablet {
  .container {
    position: fixed;
    bottom: $spacing-xlarge;
    right: 40px;
    z-index: $above + 1;
  }

  .toggle {
    padding: $spacing-medium;
    background-color: $blue-70;
    border-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: $elevation-12;
    display: flex;
    align-items: center;
    justify-content: center;

    svg path {
      fill: $white;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: $spacing-base;
  }
}
