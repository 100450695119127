@import "src/global";

.common-block {
  background: $white;
  box-shadow: $mild-box-shadow;
  border-radius: 15px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    background: linear-gradient(to right, $white, $gray-20);
    transform: rotate(30deg);
    position: absolute;
  }
}
