@import "src/global";

.wrap {
  position: fixed;
  right: 20px;
  top: $header-height + 5px;
  z-index: $show-users-button;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-height: 537px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

.search-box {
  height: 30px;
  min-height: 30px;
}

.button {
  height: 30px;
}

.search-icon {
  margin-left: 5px;
  margin-right: 0;
}

.cross-icon {
  margin-right: 5px;
  margin-left: 0;
}