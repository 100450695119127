@import "src/global";

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-xlarge $spacing-xlarge $spacing-large;
}

.close-button {
  margin-left: auto;
}
