@import "src/global";

.modal {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-xlarge;
}

.footer {
  padding: 0;
}
