@import "src/global";

.wrap {
  margin: $spacing-xlarge 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: $spacing-large;
  color: $gray-90;
}

.warning {
  font-size: 35px;
  margin: 20px 10px 0;
  text-align: center;
  font-weight: 500;
}

.description {
  text-align: center;
  font-size: 18px;
  margin: 0 10px;
}

.icon {
  width: 50%;
}

.home-button {
  margin-top: 30px;
  text-decoration: none;
}

@include mobile {
  .icon {
    width: 100%;
  }
}
