@import "src/global";

.container {
  display: flex;
  flex-direction: row;
  gap: $spacing-xlarge;
}

.col {
  flex-grow: 1;
  gap: $spacing-large;
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - $spacing-medium);
}

@include tablet {
  .container {
    flex-direction: column;
    gap: $spacing-large;
  }

  .col {
    flex-basis: auto;
  }
}
