@import "src/global";

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  max-height: calc(100vh - $header-height - $header-gap - $bottom-offset);
  width: 264px;
  padding: $spacing-large;
  list-style: none;
  border-radius: $radius-big;
  background: $white;
  box-shadow: $elevation-1;
  z-index: $above + 1;
  margin-top: $header-gap;
}

.item {
  @include label-medium;

  display: flex;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
  gap: $spacing-base;
  padding: $spacing-base;
  border-radius: $radius-small;
  cursor: pointer;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $gray-90;

  &:hover {
    background: $blue-10;
  }
}

.icon {
  flex-shrink: 0;
}

.sub-list-item {
  @include body-small;

  padding-left: $spacing-xxlarge;
}

.links-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid $gray-20;
  padding-top: $spacing-large;
  margin-top: $spacing-large;

  .button {
    border-color: transparent;
    justify-content: flex-start;
  }
}

.active {
  color: $blue-60;
  justify-content: flex-start;
  background: $blue-10;

  .icon {
    path {
      fill: $blue-60;
    }
  }
}

@include tablet {
  .close-btn {
    position: fixed;
    top: $spacing-base;
    right: $spacing-medium;
    height: $spacing-xlarge;
    width: $spacing-xlarge;
    border-radius: $radius-small;
    z-index: $dropdown-index + 2;
  }

  .list {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100%;
    padding: $spacing-xxlarge $spacing-medium $spacing-medium $spacing-medium;
    border-radius: 0;
  }
}
