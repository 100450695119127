@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
  width: 100%;
}

.input-container {
  width: 100%;
  position: relative;
  background-color: $white;
  display: flex;
  align-items: center;
  gap: $spacing-base;

  padding: 8px 12px;
  border: 1px solid $gray-40;
  border-radius: $radius-big;

  &:hover {
    border-color: $gray-60;

    &:focus-within {
      border-color: $blue-60;
    }
  }
}

.input,
.input::placeholder {
  @include body-medium;
}

.compact .input-container {
  padding: $spacing-nano $spacing-small;
  border-radius: $radius-small;
}

.input-container-disabled {
  background-color: $gray-10;
  border-color: $gray-40;

  &:hover {
    border-color: $gray-40;
  }
}

.label {
  @include label-medium;
}

.required {
  &:after {
    display: inline;
    content: "*";
    margin-left: $spacing-nano;
    color: $red-60;
  }
}

.label-disabled {
  color: $gray-70;
}

.input {
  padding: 0;
  border: none;
  background-color: $white;
  color: $gray-90;
  outline: none;
  width: 100%;
  line-height: 20px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:disabled {
    background-color: $gray-10;
    pointer-events: none;
    color: $gray-70;
  }

  &::placeholder {
    color: $gray-60;
  }

  &::-webkit-calendar-picker-indicator {
    color: transparent;
  }

  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }
}

.description {
  @include body-small;
  color: $gray-60;
}

.icon {
  background-color: rgba(255, 0, 0, 0.32);
  width: $icon-size;
  height: $icon-size;
}

.error-container {
  .label {
    color: $red-60;
  }

  .input-container,
  .input-container:focus-within,
  .input-container:hover {
    border: 1px solid $red-60;
  }

  .description {
    color: $red-60;
  }
}

.limit {
  @include body-small;

  line-height: 16px;
  color: $gray-60;
}
