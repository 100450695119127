@import "src/global";

.section-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: $spacing-large;
  margin-bottom: $spacing-large;
}

.section-title {
  @include title-large;

  margin: 0;
}

.wrap-title-input {
  padding: 0;
}

.title-input {
  height: 21px;
  font-weight: 400;
  padding-left: 5px;
}

.title-with-button {
  display: flex;
  align-items: center;
  column-gap: $spacing-large;
}

.right-part {
  display: flex;
  align-items: center;
  column-gap: $spacing-large;
}

@include mobile {
  .section-title {
    margin: 0;
  }

  .section-wrap {
    flex-direction: column;
    gap: $spacing-base;
    margin-bottom: $spacing-base;
    align-items: center;
  }

  .right-part {
    justify-content: space-between;
    width: 100%;
  }

  .right-part > *:nth-child(2) {
    margin-left: auto;
  }
}
