@import "src/global";

.wrap {
  position: relative;
}

.container {
  position: relative;
  min-height: 48px;
  width: 480px;
  max-width: 480px;
  padding: 6px $spacing-base 6px $spacing-large;
  border-radius: $radius-big;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: $smooth-appearance;
  box-shadow: $elevation-8;
}

.hide {
  animation: $smooth-disappearance;
  opacity: 0;
}

.text {
  margin: 0;
  word-break: break-word;
  width: 100%;
}

.message {
  background-color: $gray-100;

  .text {
    color: $gray-40;
  }
}

.error {
  background-color: $red-10;

  .text {
    color: $gray-90;
  }
}

.action {
  @include label-medium;

  border: 2px solid transparent;
  border-radius: $radius-big;
  padding: 10px $spacing-large;
  color: $blue-60;
  text-decoration: none;
  offset: none;
  background-color: transparent;

  &:focus {
    border: 2px solid $blue-30;
  }
}

@include duo {
  .container {
    max-width: calc(100vw - 40px);
    min-width: calc(100vw - 40px);
  }
}
