@import "src/global";

.undo {
  z-index: $above;
  margin-left: auto;
  margin-right: 10px;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-small;
}

.overview-section {
  margin-bottom: $spacing-xlarge;
  margin-top: -$spacing-xlarge;
}

.without-assist-tool {
  margin-top: 0;
}

@include mobile {
  .overview-section {
    margin-bottom: $spacing-base;
    margin-top: -$spacing-base;
  }

  .without-assist-tool {
    margin-top: 0;
  }
}
