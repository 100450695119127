@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.overlay-container {
  position: relative;
}

.label {
  margin-bottom: $spacing-small;
}

.message {
  margin-top: $spacing-small;
  color: $gray-60;
}

.icon {
  flex-shrink: 0;
}

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.select {
  @include body-medium;

  width: 100%;
  line-height: 20px;
  box-sizing: border-box;
  padding: $spacing-base $spacing-medium;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-small;
  cursor: pointer;
  border-radius: $radius-big;
  border-width: 1px;
  border-style: solid;
  user-select: none;
  position: relative;
  color: $gray-90;
  border-color: $gray-40;
  background-color: transparent;

  .value {
    max-width: calc(100% - 20px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .input {
    @include body-medium;

    border: 0;
    width: 100%;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $gray-60;
    }
  }

  .placeholder {
    color: $gray-60;
  }

  &.error {
    border-color: $red-60;

    &:hover {
      border-color: $red-60;
    }

    &:hover:enabled {
      border-color: $red-60;
    }
  }

  &:focus,
  &.focus {
    border-color: $blue-60;
    outline: 1px solid $blue-60;
  }

  &:disabled {
    color: $gray-70;
    background-color: $gray-10;
    border-color: $gray-40;

    &:hover {
      cursor: initial;
    }
  }

  &:hover:enabled {
    border-color: $gray-60;
  }

  &:active:enabled {
    color: $blue-70;
    border-color: $blue-40;
  }

  &:disabled svg path {
    fill: $gray-60;
  }

  &:active:enabled svg path {
    fill: $blue-70;
  }
}

.error {
  .label,
  .message {
    color: $red-60;
  }
}

.icon-open {
  transform: rotate(180deg);
}

@include mobile {
  .button {
    -webkit-tap-highlight-color: transparent;
  }
}
