@import "src/global";

@mixin fillPath($color) {
  &:not(.custom-color) {
    svg {
      path {
        fill: $color;
      }
    }
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: $spacing-base;
  cursor: pointer;
  border-radius: $radius-big;
  border-width: 1px;
  border-style: solid;
  user-select: none;
  white-space: nowrap;

  * {
    pointer-events: none;
  }

  svg {
    flex-shrink: 0;
    pointer-events: none;
  }

  &.small {
    padding: 4px;
    border-radius: 4px;
  }

  &.small svg {
    transform: scale(0.8);
  }

  &.with-badge {
    position: relative;
  }

  &.with-badge::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $blue-60;
    position: absolute;
    top: 0;
    right: 0;
  }

  &.with-badge:disabled::before {
    background-color: $gray-50;
  }

  .loader-icon {
    animation: $spinner-animation;
  }

  &.primary {
    @include fillPath($blue-60);
  }

  &.secondary {
    @include fillPath($gray-80);
  }

  &.dark.primary {
    @include fillPath($gray-10);

    background-color: $gray-100;
  }

  &.dark.primary:disabled {
    background-color: $gray-80;
  }

  &.dark.secondary {
    @include fillPath($gray-40);
  }

  &.primary,
  &.secondary {
    &:disabled {
      @include fillPath($gray-50);
    }
  }

  &.contained {
    background-color: $gray-10;
  }

  &.outlined {
    background-color: transparent;
  }

  &.contained,
  &.outlined {
    border-color: transparent;

    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled {
      border-color: transparent;
      background-color: $gray-20;
    }

    &:focus:enabled {
      border-color: $blue-30;
      outline: 1px solid $blue-30;
    }
  }

  &.dark.contained,
  &.dark.outlined {
    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled {
      @include fillPath($gray-40);

      border-color: transparent;
      background-color: $gray-90;
    }
  }

  &:focus {
    border-color: $blue-30;
    outline: 1px solid $blue-30;
  }

  &:disabled {
    color: $gray-50;
    background-color: $gray-20;
    border-color: transparent;

    @include fillPath($gray-50);

    &:hover {
      cursor: initial;
    }
  }

  &.small:disabled {
    background-color: transparent;
  }
}

.elevation-1 {
  box-shadow: $elevation-1;
}

.elevation-3 {
  box-shadow: $elevation-3;
}

.elevation-8 {
  box-shadow: $elevation-8;
}

.elevation-12 {
  box-shadow: $elevation-12;
}

@include mobile {
  .button {
    -webkit-tap-highlight-color: transparent;
  }
}

@keyframes delay-visibility {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}
