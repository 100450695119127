@import "src/global";

.container {
  display: flex;
  align-items: flex-start;
  padding: $spacing-small $spacing-base;

  &:hover {
    background-color: $gray-10;
  }
}

.container > *:nth-child(2) {
  width: 190px;
  margin: $spacing-nano $spacing-large auto $spacing-base;
}

.buttons {
  display: flex;
  align-items: center;
}

.revision-select-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  color: $black;
  padding: $spacing-nano $spacing-small;
  border-color: transparent;
  background-color: transparent;

  &.selected {
    font-weight: $font-medium;

    &:hover {
      cursor: default;
    }
  }
}

.revision-name {
  @include body-medium;

  width: 100%;
  line-height: 20px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-wrap: break-word;
}

.selected {
  .revision-name {
    @include label-large;
  }
}

.revision-name-icon {
  color: $red-60;
}

@include mobile {
  .container > *:nth-child(2) {
    width: 150px;
  }
}
