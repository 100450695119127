@import "src/global";

.select-list-item {
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  margin-right: -2px;
  margin-left: -2px;
  background-color: $white;
  padding-bottom: $spacing-base;
  padding-top: $spacing-base;
}

.input-container {
  display: flex;
  width: 100%;
  gap: $spacing-base;
  align-items: center;
  position: relative;
}

.is-dragging {
  border: 2px solid $ghost-blue-transparent;
  border-radius: 10px;
}

.drag-icon-container {
  padding: $spacing-base;
  display: flex;
  align-items: center;
}

.drag {
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.drag-disabled {
  &:hover {
    cursor: unset;
  }

  path {
    fill: $gray-60;
  }
}
