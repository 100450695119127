@import "src/global";

.container {
  border-radius: $radius-big;
  border: 1px solid $gray-30;
  padding: $spacing-large $spacing-xlarge $spacing-xlarge;
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
}

.inputs {
  display: flex;
  gap: $spacing-xlarge;
}

.input-container {
  flex-basis: 50%;
  align-items: center;
}

.project-select {
  height: 44px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $shadow-grey;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}

.project-select-text {
  margin: 0;
  color: $gray-100;
  font-size: 14px;
  font-weight: 500;
  text-align: start;
}

.arrow-down {
  position: absolute;
  width: 13px;
  right: 15px;
}

.rotate {
  transform: rotate(180deg);
}

.serial-number {
  @include label-medium;

  padding: $spacing-base;
}

.active-project-select {
  border-color: $blue-60;

  .arrow-down {
    transform: rotate(180deg);

    * {
      stroke: $blue-60;
    }
  }
}

.dropdown-body {
  width: 250px;
}

.date-selectors-title {
  @include label-medium;

  margin: 0 0 $spacing-small;

  &.required {
    &:after {
      display: inline;
      content: "*";
      color: $red;
      margin-left: $spacing-nano;
    }
  }

  &.disabled {
    color: $gray-70;

    &:after {
      color: $gray-70;
    }
  }
}

.tools-wrap {
  display: flex;
  flex-direction: column;
  gap: $spacing-medium;
}

.date-selectors {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.present-switch {
  font-size: 14px;
  display: flex;
}

.date-pickers {
  display: flex;
  flex-direction: row;
  column-gap: $spacing-base;
}

.date-picker-fallback {
  height: 44px;
  border-radius: 8px;
  border: 1px solid #d7dbe3;
  padding: 0 20px;
  width: 208.1px;
}

.undo {
  z-index: $above;
  margin-left: auto;
  margin-right: 10px;
}

.controls {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-end;
  column-gap: $spacing-base;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include label-medium;

  margin: 0 0 $spacing-small;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  min-height: 30px;

  .title {
    margin: 0;
  }
}

@include tablet {
  .header {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    gap: $spacing-base;
  }

  .inputs {
    flex-direction: column;
    gap: 0;
  }

  .present-switch {
    align-self: flex-end;
  }

  .date-selectors {
    flex-grow: 1;
  }

  .controls {
    justify-content: center;
    align-self: flex-end;
  }
}

@include duo {
  .header {
    flex-direction: column-reverse;
    gap: $spacing-base;
    align-items: flex-start;
  }
}

@include mobile {
  .container {
    padding: $spacing-medium;
    gap: $spacing-medium;
  }

  .date-pickers {
    flex-direction: column;
    gap: $spacing-base;
  }

  .controls {
    margin-bottom: $spacing-medium;
    column-gap: $spacing-small;
  }

  .present-switch {
    align-self: center;
  }
}
