@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
  padding: $spacing-base;
  width: 360px;
  max-width: 100%;
}

.content {
  display: flex;
  gap: $spacing-base;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-medium;
}
