@import "src/global";

.footer {
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: $spacing-xlarge;
  gap: $spacing-medium;
}

.content {
  display: flex;
  gap: $spacing-medium;
}

.supporting-text {
  display: inline-flex;
  gap: $spacing-small;
  color: $gray-70;
  margin-right: auto;
}

@include duo {
  .supporting-text {
    align-self: center;
    margin-right: unset;
  }

  .footer {
    flex-direction: column;
  }
}
