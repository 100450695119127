@use "sass:map";
@use "sass:meta";
@use "sass:string";

$font-normal: 400;
$font-medium: 500;

@mixin typography($args) {
  font-weight: nth($args, 1);
  font-size: nth($args, 2) + px;
  line-height: nth($args, 3) + px;
  letter-spacing: nth($args, 4) + px;
}

// prettier-ignore
$config: (
  display-large: ($font-normal, 57, 64, -0.25),
  display-medium: ($font-normal, 45, 52, 0),
  display-small: ($font-normal, 36, 44, 0),
  headline-large: ($font-normal, 32, 40, 0),
  headline-medium: ($font-normal, 28, 36, 0),
  headline-small: ($font-normal, 24, 32, 0),
  title-large: ($font-normal, 22, 28, 0),
  title-medium: ($font-medium, 16, 24, 0.15),
  title-small: ($font-medium, 14, 20, 0.1),
  body-large: ($font-normal, 16, 24, 0.1),
  body-medium: ($font-normal, 14, 20, 0.1),
  body-small: ($font-normal, 12, 16, 0.15),
  label-large: ($font-medium, 14, 20, 0.1),
  label-medium: ($font-medium, 12, 16, 0.25),
  label-small: ($font-medium, 11, 16, 0.25),
);

@mixin display-large {
  @include typography(map.get($config, display-large));
}

@mixin display-medium {
  @include typography(map.get($config, display-medium));
}

@mixin display-small {
  @include typography(map.get($config, display-small));
}

@mixin headline-large {
  @include typography(map.get($config, headline-large));
}

@mixin headline-medium {
  @include typography(map.get($config, headline-medium));
}

@mixin headline-small {
  @include typography(map.get($config, headline-small));
}

@mixin title-large {
  @include typography(map.get($config, title-large));
}

@mixin title-medium {
  @include typography(map.get($config, title-medium));
}

@mixin title-small {
  @include typography(map.get($config, title-small));
}

@mixin body-large {
  @include typography(map.get($config, body-large));
}

@mixin body-medium {
  @include typography(map.get($config, body-medium));
}

@mixin body-small {
  @include typography(map.get($config, body-small));
}

@mixin label-large {
  @include typography(map.get($config, label-large));
}

@mixin label-medium {
  @include typography(map.get($config, label-medium));
}

@mixin label-small {
  @include typography(map.get($config, label-small));
}

.display-large {
  @include display-large;
}

.display-medium {
  @include display-medium;
}

.display-small {
  @include display-small;
}

.headline-large {
  @include headline-large;
}

.headline-medium {
  @include headline-medium;
}

.headline-small {
  @include headline-small;
}

.title-large {
  @include title-large;
}

.title-medium {
  @include title-medium;
}

.title-small {
  @include title-small;
}

.body-large {
  @include body-large;
}

.body-medium {
  @include body-medium;
}

.body-small {
  @include body-small;
}

.label-large {
  @include label-large;
}

.label-medium {
  @include label-medium;
}

.label-small {
  @include label-small;
}
