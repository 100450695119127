@import "src/global";

.notifications-box {
  right: 0;
  margin-right: 30px;
  bottom: 20px;
  position: fixed;
  z-index: $toast-notification-index;
  display: flex;
  flex-direction: column;
  row-gap: $spacing-large;
}

@include duo {
  .notifications-box {
    margin-right: 20px;
  }
}
