@import "src/global";

@mixin fillPath($color) {
  svg {
    path {
      fill: $color;
    }
  }
}

.button {
  @include label-medium;

  line-height: 20px;
  box-sizing: border-box;
  padding: $spacing-base $spacing-large;
  margin: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-small;
  cursor: pointer;
  border-radius: $radius-big;
  border-width: 1px;
  border-style: solid;
  user-select: none;
  white-space: nowrap;

  * {
    pointer-events: none;
  }

  svg {
    flex-shrink: 0;
  }

  &.with-left-icon {
    padding-left: $spacing-base;
  }

  .loader-icon {
    animation: $spinner-animation;
  }

  &.primary {
    color: $white;
    background-color: $blue-60;
    border-color: transparent;

    @include fillPath($white);

    &:hover:enabled {
      color: $white;
      background-color: $blue-50;
      border-color: $blue-30;
    }

    &:active:enabled {
      color: $white;
      background-color: $blue-70;
      border-color: $blue-40;
    }
  }

  &.secondary {
    color: $blue-60;
    background-color: $blue-10;
    border-color: transparent;

    @include fillPath($blue-60);

    &:hover:enabled {
      color: $blue-60;
      background-color: $blue-20;
    }

    &:active:enabled {
      color: $blue-70;
      background-color: $blue-30;
      border-color: $blue-40;

      @include fillPath($blue-70);
    }
  }

  &.tertiary {
    color: $blue-60;
    border-color: $blue-20;
    background-color: transparent;

    @include fillPath($blue-60);

    &:hover:enabled {
      color: $blue-60;
      background-color: $blue-20;
      border-color: $blue-30;
    }

    &:active:enabled {
      color: $blue-70;
      background-color: $blue-30;
      border-color: $blue-40;

      @include fillPath($blue-70);
    }

    &:disabled:not(.icon) {
      background-color: transparent;
      border-color: $gray-30;
    }
  }

  &.text {
    color: $gray-80;
    background-color: transparent;
    border-color: transparent;

    @include fillPath($gray-80);

    padding: $spacing-base $spacing-small;

    &.with-left-icon {
      padding-left: $spacing-nano;
    }

    &:hover:enabled {
      color: $blue-60;
      background-color: transparent;
      border-color: transparent;

      @include fillPath($blue-60);
    }

    &:active:enabled {
      color: $blue-70;
      background-color: transparent;
      border-color: transparent;

      @include fillPath($blue-70);
    }

    &:disabled:not(.icon) {
      background-color: transparent;
    }
  }

  &.dark {
    color: $blue-40;
    background-color: transparent;
    border-color: transparent;

    @include fillPath($white);

    &:hover:enabled {
      color: $blue-30;
      background-color: transparent;
      border-color: transparent;
    }

    &:active:enabled {
      color: $blue-50;
      background-color: transparent;
      border-color: transparent;
    }
  }

  &:focus {
    border-color: $blue-30;
    outline: 1px solid $blue-30;
  }

  &:disabled {
    color: $gray-50;
    background-color: $gray-20;
    border-color: transparent;

    @include fillPath($gray-50);

    &:hover {
      cursor: initial;
    }
  }
}

@include mobile {
  .button {
    -webkit-tap-highlight-color: transparent;
  }
}

@keyframes delay-visibility {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}
