@import "src/global";

$option-block-height: 160px;
$option-block-height-tablet: 200px;
$option-block-height-mobile: 280px;
$option-block-margin-bottom: 20px;

$content-offset: calc((100vw - $content-width - $content-padding) / 2);

.cv-content {
  display: flex;
  padding: 0 $content-padding + $scroll-bar-width;
  width: 100%;
}

.wrapper {
  display: flex;
  gap: $spacing-xlarge;
  height: 100%;
  max-height: calc(100vh - $header-height);
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.sections {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: max(calc($content-offset - $scroll-bar-width), 6px);
  margin-right: min(calc($content-offset * -1), -14px);
  padding-left: $spacing-small;
  margin-left: -$spacing-small;
  padding-top: $header-gap;
}

.sections > * {
  flex-shrink: 0;
}

.sections > *:nth-last-of-type(1) {
  padding-bottom: $bottom-offset;
}

.settings-container {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  border-radius: 10px;
  background-color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  transition:
    max-height $options-block-animation ease-in-out,
    margin-bottom $options-block-animation ease-in-out,
    opacity $options-block-animation ease-in-out;
  max-height: 0;
  margin-bottom: 0;
  opacity: 0;
  box-shadow: $elevation-1;
  overflow: hidden;
}

.settings-container > div:first-child {
  margin: 20px;
}

.show {
  max-height: $option-block-height;
  margin-bottom: $option-block-margin-bottom;
  opacity: 1;
  overflow: visible;
}

.warnings-box {
  left: 0;
  margin-left: 20px;
  bottom: 20px;
  position: fixed;
  z-index: $toast-notification-index;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

@include tablet {
  .warnings-box {
    margin-right: 20px;
  }

  .cv-content {
    margin-left: unset;
  }

  .wrapper {
    gap: $spacing-large;
  }
}

@include tablet {
  .show {
    max-height: $option-block-height-tablet;
  }
}

@include duo {
  .cv-content {
    width: 100%;
    padding: 0 $content-padding;
  }

  .sections {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    padding: $header-gap $spacing-small 0;
    margin: 0 (-$spacing-small);
  }
}

@include mobile {
  .cv-content {
    width: 100%;
    margin-left: unset;
  }

  .show {
    max-height: $option-block-height-mobile;
  }
}
