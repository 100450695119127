@import "src/global";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $header-height;
  height: $header-height;
  width: 100%;
  border-bottom: 4px solid $blue-10;
  background: $white;
  padding: 0 $content-padding + $scroll-bar-width;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: $content-width;
}

.logo {
  display: flex;
  gap: $spacing-medium;
  border: none;
  background: $white;
  cursor: pointer;
  padding: 0;
}

.icon {
  svg {
    path {
      fill: $blue-60;
    }
  }
}

.template-selector-container {
  margin-right: $spacing-base;
}

.button-container {
  display: flex;
  gap: $spacing-base;
  align-items: center;
}

.divider-container {
  display: flex;
  align-items: center;

  &:after {
    content: "";
    width: 0;
    height: 24px;
    display: block;
    margin: 0 $spacing-large;
    border-left: 1px solid $gray-30;
    flex-shrink: 0;
  }
}

.container > *:nth-child(2) {
  margin-left: 110px;
}

.container > *:nth-child(3) {
  margin-right: $spacing-base;
}

.container > *:nth-child(5) {
  margin-left: auto;
}

@include tablet {
  .logo {
    margin-right: $spacing-base;
  }

  .container > *:nth-child(2) {
    margin-left: auto;
    margin-right: 0;
  }

  .divider-container {
    &:after {
      display: none;
    }
  }
}
