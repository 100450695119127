@import "src/global";

$animation-transition: 0.1s;

$height: 20px;
$gap: 4px;

$radius: $height - 2 * $gap;
$width: 2 * $radius + 2 * $gap;

.switch-box {
  display: flex;
  align-content: center;
  justify-content: flex-start;
}

.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  margin-right: 10px;
  flex-shrink: 0;
}

.switch input {
  width: $width - 2 * $gap;
  height: $height - 1.5 * $gap;
  outline-offset: 5px;
  outline-color: $black;
  margin: 3px;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #ccc;
  transition: $animation-transition;
  border-radius: $height;
}

.slider::before {
  position: absolute;
  content: "";
  height: $radius;
  width: $radius;
  left: $gap;
  bottom: $gap;
  background-color: $white;
  transition: $animation-transition;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $blue-60;
}

input:checked + .slider::before {
  transform: translateX($radius);
}
