@import "src/global";

#app {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
}

html {
  height: 100%;
}

body {
  background-color: $body-background;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: $gray-90;
  margin: 0;
}

:global(#modal-root) {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $body-background;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-60;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $gray-80;
}
