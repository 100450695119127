@import "src/global";

.btn-container {
  display: flex;
  gap: $spacing-large;
}

@include duo {
  .btn-container {
    flex-direction: column;
  }
}
