@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-large;
  padding: $spacing-base;
}

.buttons {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: flex-start;
  gap: $spacing-medium;
}

.translate-selectors-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: $spacing-base;
  align-items: flex-end;
}

.spinner-icon {
  animation: $spinner-animation;
}
