@import "src/global";

.assist-button {
  position: absolute;
  box-shadow:
    0 1px 3px 1px rgb(0 0 0 / 15%),
    0 1px 2px 0 rgb(0 0 0 / 30%);
  border-radius: 3px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $blue-70;
    }
  }
}
