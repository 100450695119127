@import "src/global";

.wrap {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: $dropdown-index;
  scroll-behavior: smooth;
}

.triangle {
  width: 0;
  height: 0;
  z-index: $dropdown-index + 2;
  border-style: solid;
  border-width: 0 10px 15px;
  border-color: transparent transparent $white transparent;
}

.triangle-on-bottom {
  width: 0;
  height: 0;
  z-index: $dropdown-index + 2;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: $white transparent transparent transparent;
}

.body {
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: $dropdown-index + 1;
  box-shadow: $light-black-box-shadow;
}

.search-box {
  margin: 10px;
  height: 40px;
}

.empty-search-box {
  width: 210px;
  height: 10px;
}

.reset-button {
  width: 210px;
  height: 39px;
  font-size: 14px;
  line-height: 16px;
  color: $blue-60;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 0;

  &:hover {
    background-color: $gray-20;
  }
}

.line {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 212px;
  border-top: 1px solid $gray-30;
}

.hidden {
  display: none;
  position: absolute;
}

.content {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.content-box {
  overflow-y: scroll;
  margin-left: 10px;
  margin-right: 10px;
  overscroll-behavior: contain;
}
