@import "src/global";

$accessory-panel-width: 76px;

.wrap {
  display: grid;
  border-radius: $radius-big;
  background-color: $white;
  border: 1px solid $gray-40;
  position: relative;
  padding: $spacing-base $spacing-medium;

  &.with-panel {
    padding-right: $spacing-small;
  }

  &.wrap-resizable {
    border-radius: $radius-big $radius-big 0 $radius-big;
  }

  &.disabled {
    background-color: $gray-10;
    pointer-events: none;
  }

  &:focus-within {
    outline: none !important;
    border-color: $blue-60;
  }

  &.error {
    border-color: $red;

    &:focus-within {
      border-color: $red;
    }
  }

  &::after {
    @include body-medium;
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    margin-right: $accessory-panel-width;
    overflow: hidden;
  }

  .text-area {
    resize: none;
    overflow: hidden;
  }

  &::after,
  .text-area {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.text-area {
  @include body-medium;

  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: $gray-90;
  resize: none;
  overscroll-behavior: contain;
  padding: 0;

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    @include body-medium;

    color: $gray-60;
  }

  &:disabled {
    color: $gray-70;
  }

  &.resizable {
    resize: vertical;
    max-height: 500px;
  }
}

.with-panel {
  .text-area {
    padding-right: $accessory-panel-width;
  }
}

.description {
  @include body-small;

  margin-top: $spacing-small;
  color: $gray-60;
}

.accessory-panel {
  width: $accessory-panel-width;
  position: absolute;
  top: 0;
  right: 6px;
  padding: $spacing-base;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  &.panel-resizeable {
    padding: $spacing-base 0 $spacing-base $spacing-base;
    margin-right: 12px;
  }
}

.counter {
  color: $gray-60;
  align-self: flex-end;
}
