@import "src/global";

.content {
  @include body-small;

  line-height: 16px;
  position: fixed;
  visibility: hidden;
  z-index: $dropdown-index + 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  padding: $spacing-small $spacing-base;
  border-radius: $radius-small;
  background: $gray-90;
  text-align: center;
}

.open {
  visibility: visible;
}
