@import "src/global";

.container {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  gap: $spacing-xlarge;
}

.select-container {
  min-width: 240px;
}

.settings {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: flex-end;
  row-gap: $spacing-large;
  padding: $spacing-base 0;
  margin-top: $spacing-large;
  width: 100%;
}

.settings > * {
  width: 50%;
  padding-right: $spacing-medium;
}

.buttons {
  display: flex;
  gap: $spacing-medium;
}

@include duo {
  .top {
    flex-direction: column;
  }

  .settings {
    max-height: 180px;
    overflow-y: auto;
  }

  .settings > * {
    width: 100%;
    padding-right: 0;
  }
}
