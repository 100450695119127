@import "src/global";

.modal-container {
  z-index: $modal-window-index;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  overflow-y: auto;
  animation: $smooth-appearance;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: $gray-100;
  }
}

.modal-container-open {
  display: flex;
}

.modal {
  max-width: 90vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: $radius-big;
  background-color: $white;
}

.small {
  width: 552px;
}

.medium {
  width: 730px;
}

@include duo {
  .modal {
    max-width: unset;
    width: 95vw;
  }
}
