@import "src/global";

.wrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: $radius-big;
  background-color: $gray-10;
  border: 1px solid $gray-20;
  position: relative;

  &:hover {
    border-color: $gray-80;
  }

  &:focus-within {
    outline: none !important;
    border-color: $blue-60;

    .left-icon svg *,
    .right-icon svg * {
      stroke: $blue-60;
    }
  }

  &.error {
    border-color: $red;
  }

  &.disabled:hover {
    border: 1px solid $gray-20;
  }
}

.input {
  @include body-medium;

  height: 100%;
  width: 100%;
  padding: 0 14px;
  border: 0;
  border-radius: $radius-big;
  background-color: inherit;
  color: $gray-90;

  &:disabled {
    background-color: transparent;
    color: $gray-60;
  }

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    @include body-medium;

    color: $gray-60;
  }

  &::-webkit-calendar-picker-indicator {
    color: transparent;
  }
}

.left-icon,
.right-icon {
  display: flex;
  align-items: center;
}

.left-icon {
  margin-right: 15px;
}

.right-icon {
  margin-left: 15px;
}

@include mobile {
  .input {
    -webkit-tap-highlight-color: transparent;
  }
}
