@import "src/global";

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .button {
    border-color: transparent;
    justify-content: flex-start;
  }
}

.create-revision-icon-plus {
  margin-right: 5px;

  path {
    fill: $gray-90;
  }
}

.cv-file-name {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: $spacing-small;

  svg {
    path {
      fill: $blue-60;
    }
  }
}

.revision-label {
  @include label-medium;
}

.cv-file-name-text {
  @include label-medium;

  text-decoration: none;
  color: $gray-80;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.cv-select-arrow-down-open {
  transform: rotate(180deg);
}

.revisions-list {
  overflow-y: auto;
  margin: 0;
  padding: 0;

  $revision-height: 38px;
  $max-revisions-count: 8;

  min-height: calc($revision-height * 1);
  max-height: calc($revision-height * $max-revisions-count);
  display: flex;
  flex-direction: column;
}

.select-revision-line {
  height: 1px;
  width: 100%;
  margin: $spacing-small 0;
  background-color: $gray-30;
}

.create-revision-btn-active {
  &:hover {
    .create-revision-icon-plus path {
      fill: $blue-60;
    }

    background-color: $gray-20;
  }
}

.revision-name-icon {
  color: #f00;
  font-size: 25px;
}

@include duo {
  .cv-file-name-text {
    max-width: 30px;
  }
}

@include mobile {
  .revisions-list {
    min-width: 280px;
  }
}
