@import "src/global";

.container {
  line-height: 20px;
  padding: $spacing-base;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: $spacing-small;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: $radius-small;
  user-select: none;
  white-space: nowrap;
  background-color: transparent;
  outline: none;
  position: relative;
  align-self: flex-start;
  width: 100%;

  &:focus:enabled {
    border-color: $blue-30;
  }

  &:hover:enabled {
    background-color: $gray-10;
  }
}

.selectable {
  padding-right: 40px;
}

.highlighted {
  border-color: $blue-30;
}

.icon-selected {
  position: absolute;
  right: $spacing-base;
  top: 50%;
  transform: translateY(-50%);
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
