@import "src/global";

.selected-cv-name {
  @include label-medium;

  cursor: pointer;
  color: $gray-90;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: row;
}

.dropdown-button-icon {
  path {
    fill: $blue-60;
  }
}

.dropdown-button-clicked {
  transform: rotate(180deg);
}

.template-label {
  @include label-medium;
}

.template-name {
  @include label-medium;

  color: $gray-80;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(50vw - 270px);
  margin-left: $spacing-small;
}

.select-cv-language {
  color: $gray-60;
  font-weight: 500;
  margin-left: $spacing-small;
}
