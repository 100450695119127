@import "src/global";

.content {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  visibility: hidden;
  background-color: $white;
  border-radius: $radius-small;
  z-index: $dropdown-index + 1;
  box-shadow: $elevation-3;
  padding: $spacing-base;
  max-width: calc(100vw - $content-padding * 2);
}

.open {
  visibility: visible;
}
