@import "src/global";

.option {
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;

  &:hover {
    background-color: $gray-20;
  }
}

.avatar-and-name {
  display: flex;
  overflow: hidden;
}

.avatar-container {
  margin-right: 10px;
}

.avatar {
  width: 22px;
  height: 22px;
}

.name {
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  word-wrap: break-word;
}

.temp {
  width: 30px;
  padding-left: 3px;
}

.checkbox {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 10px;
  border: 1px solid $gray-50;
  display: flex;
  align-items: center;
  justify-content: center;

  &[aria-checked="true"] {
    background-color: $blue-60;
    overflow: hidden;
  }
}

.checkbox-checked-inner {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background-color: $white;
}
