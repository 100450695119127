@import "src/global";

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.hidden {
  display: none;
}

.warning-modal {
  width: 580px;
  height: 140px;
  min-width: auto;
  padding: 10px;
}

.warning-content {
  color: $gray-90;
}

.warning-title {
  color: $gray-90;
}

.card {
  width: 423px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgb(229 231 238 / 30%);
}

.header {
  height: 223px;
  width: 423px;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.header-text {
  position: absolute;
  left: unset;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 150px;
}

.lx-group,
.product-name {
  font-variant: all-small-caps;
  line-height: 18px;
  margin: 0;
}

.lx-group {
  color: $gray-30;
  font-size: 13px;
  font-weight: 300;
}

.product-name {
  color: $white;
  font-size: 20px;
  font-weight: 500;
}

.content {
  padding: 44px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
}

.content-header {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $gray-90;
}

.subheader {
  margin-top: 15px;
  margin-bottom: 45px;
  text-align: center;
  color: $gray-60;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.dev-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.dev-login-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 15px;

  input {
    font-size: 15px;
    width: 250px;
  }

  button {
    border: 1px solid $gray-100;
    background-color: $gray-50;
    font-size: 15px;
    padding: 3px;
    margin-top: 15px;
    font-weight: 500;
    color: $black;
    cursor: pointer;
  }
}

.dev-form-title {
  margin-bottom: 10px;
}

.google-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  span {
    color: $white;
  }
}

@include mobile {
  .card {
    height: 100%;
    width: 100%;
    border-radius: unset;
  }

  .warning-modal {
    margin: auto 15px;
    overflow: hidden;
    height: fit-content;
  }

  .warning-content,
  .warning-title {
    padding-left: 10px;
  }

  .header {
    height: 50%;
    width: 100%;
    justify-content: center;
    overflow: hidden;
  }

  .svg {
    align-self: center;
    margin-left: calc((100% - 500px) / 2);
  }

  .header-text {
    position: absolute;
    margin-left: 0;
    left: 19px;
    margin-bottom: 17px;
  }

  .subheader {
    margin-top: 25px;
  }

  .content {
    height: 50%;
  }
}
