@import "src/global";

.red-button {
  position: fixed;
  right: -35px;
  top: 50%;
  width: 100px;
  text-align: center;
  height: 32px;
  transform: rotate(270deg);
  border-radius: 10px 10px 0 0;
  background-color: $red;
  color: $white;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  z-index: $feedback-button;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.logo {
  margin-right: $spacing-xlarge;
  width: 30px;
  height: 30px;
}

.content {
  width: 100%;
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.message-input {
  width: 100%;
}

.footer {
  justify-content: center;
}

.close-btn {
  right: 20px;
  top: 20px;
}
