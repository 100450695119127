@import "src/global";

.input-list-item {
  display: flex;
  border: 2px solid transparent;
  margin-right: -2px;
  margin-left: -2px;
  background-color: $white;
  flex-direction: column;
  padding-bottom: $spacing-base;
  padding-top: $spacing-base;
}

.input-container {
  display: flex;
  width: 100%;
  gap: $spacing-base;
  align-items: flex-start;
  position: relative;
}

.input.input-container {
  align-items: center;
}

.validation-message {
  margin-left: 44px;
  margin-top: $spacing-small;
  color: $gray-60;
}

.is-dragging {
  border: 2px solid $ghost-blue-transparent;
  border-radius: 10px;
}

.drag-icon-container {
  padding: $spacing-base;
  display: flex;
  align-items: center;
}

.drag {
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.drag-disabled {
  &:hover {
    cursor: unset;
  }

  path {
    fill: $gray-60;
  }
}

.control {
  width: 100%;
}
