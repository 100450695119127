@import "src/global";

// TODO move styles from this file to global.scss and import them directly from css (@include)
.section {
  padding: $spacing-xlarge;
  border-radius: $radius-big;
  background-color: $white;
  position: relative;
  box-shadow: $elevation-1;
  margin-bottom: $spacing-xlarge;
  display: flex;
  flex-direction: column;
}

.section-heading {
  @include title-medium;

  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  background-color: $white;

  &.small {
    height: $spacing-xxlarge;
  }

  textarea {
    overscroll-behavior: auto;
  }

  textarea,
  input {
    &:disabled {
      background-color: transparent;
      color: $gray-60;
    }
  }
}

.warning-message {
  @include body-small;

  color: $gray-60;
  margin-top: $spacing-small;
  word-wrap: break-word;
  word-break: break-word;
  visibility: hidden;
}

.warning-message-visible {
  visibility: visible;
}

@include mobile {
  .section {
    padding: $spacing-medium;
  }
}

@include iphone {
  .input {
    textarea,
    input {
      &:disabled {
        color: $gray-90;
      }
    }
  }
}
