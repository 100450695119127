@import "typography";

// new color palette
$gray-10: #f8f9fc;
$gray-20: #f1f3f6;
$gray-30: #e9ecf2;
$gray-40: #d4d9e3;
$gray-50: #b6bdcc;
$gray-60: #9fa8b9;
$gray-70: #81899b;
$gray-80: #5e6574;
$gray-90: #3d424e;
$gray-100: #242830;

$blue-10: #edf4ff;
$blue-20: #e4efff;
$blue-30: #bfd8ff;
$blue-40: #82b2ff;
$blue-50: #5790ff;
$blue-60: #3f75ff;
$blue-70: #225fbf;
$blue-80: #1852ad;
$blue-90: #0f4599;
$blue-100: #06398a;

$red-10: #fee;
$red-20: #ffe4e4;
$red-30: #ffbfbf;
$red-40: #ff8c8c;
$red-50: #f55;
$red-60: #eb2525;
$red-70: #bb1c1c;
$red-80: #9d1414;
$red-90: #841010;
$red-100: #740c0c;

// colors
$white: #fff;
$body-background: $gray-10;
$shadow-grey: #d7dbe3;

$ghost-blue: #66a1ff;
$ghost-blue-transparent: transparentize($ghost-blue, 0.5);

$red: #d24946;
$golden-orange: #fd891e;
$warning: #ff7c00;

$black: #000;

// shadows
$mild-box-shadow: 0 6px 12px $shadow-grey;
$gray-box-shadow: 0 6px 12px $gray-30;
$light-blue-box-shadow: 0 6px 12px $blue-20;
$light-black-box-shadow: 0 10px 60px rgb(43 43 46 / 25%);
$light-black-bottom-box-shadow: 0 10px 15px rgb(43 43 46 / 18%);
$elevation-1:
  0 1px 3px 1px rgb(129 137 155 / 15%),
  0 1px 2px 0 rgb(129 137 155 / 30%);
$elevation-3:
  0 2px 6px 2px rgb(129 137 155 / 15%),
  0 1px 2px 0 rgb(129 137 155 / 30%);
$elevation-8:
  0 2px 3px 0 rgb(129 137 155 / 30%),
  0 6px 10px 4px rgb(129 137 155 / 15%);
$elevation-12:
  0 4px 4px 0 rgb(129 137 155 / 30%),
  0 8px 12px 6px rgb(129 137 155 / 15%);

// z-index stack
$base: 0;
$above: 10;

$nav-element-index: $above;
$scroll-underlay-index: $nav-element-index - 1;
$dropdown-index: $nav-element-index + $above;
$modal-window-index: $dropdown-index + $above;
$toast-notification-index: $modal-window-index + $above;
$feedback-button: $modal-window-index - 1;
$show-users-button: $above + 1;

// responsive breakpoints
$mobile-width: 500px;
$duo-width: 620px;
$tablet-width: 1024px;

// spacing
$icon-button-size: 30px;
$icon-size: 20px;

$spacing-nano: 2px;
$spacing-small: 4px;
$spacing-base: 8px;
$spacing-medium: 12px;
$spacing-large: 16px;
$spacing-xlarge: 24px;
$spacing-xxlarge: 36px;

$radius-small: 4px;
$radius-big: 8px;

// layout
$header-height: 68px;
$header-gap: 20px;
$bottom-offset: 20px;
$content-width: 1128px;
$content-padding: 12px;

$scroll-bar-width: 8px;

// timing
$options-block-animation: 0.5s;

// mixins
@mixin media($max) {
  /* stylelint-disable media-query-no-invalid */
  @media screen and (max-width: $max) {
    @content;
  }
  /* stylelint-enable media-query-no-invalid */
}

@mixin mobile {
  @include media($mobile-width) {
    @content;
  }
}

@mixin iphone {
  @include mobile {
    @media screen and (resolution >= 2dppx) {
      @content;
    }
  }
}

@mixin duo {
  @include media($duo-width) {
    @content;
  }
}

@mixin tablet {
  @include media($tablet-width) {
    @content;
  }
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$spinner-animation: rotate-clockwise 0.7s infinite linear;

$smooth-appearance: appearance-animation 225ms cubic-bezier(0.4, 0, 0.2, 1);
$smooth-disappearance: disappearance-animation 225ms cubic-bezier(0.4, 0, 0.2, 1);

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes appearance-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappearance-animation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes loader {
  0% {
    left: -280px;
  }

  100% {
    left: 1000px;
  }
}

$loader: loader 1s linear 0.6s infinite;

.text-center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}
