@import "src/global";

.content {
  @include body-medium;

  display: flex;
  flex-direction: column;
  padding: 0 $spacing-xlarge;
  width: 100%;
  word-wrap: break-word;
}
